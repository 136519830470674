/*
 * This is the "core" webpack which should contain modules used throughout the
 * entire project. Be careful of adding modules which are intrusive by default,
 * such as UI frameworks; stuff imported here should be opt-in as much as
 * possible.
 */

// ActionCable
import "channels"

// Turbo (disable Turbo Drive by default -- https://github.com/hotwired/turbo/pull/196)
import { Turbo } from "@hotwired/turbo-rails"

// New behavior to disable Turbo Drive by default from version 7.0.0
Turbo.session.drive = false;

// Stimulus
import "stimulus_controllers"

// Charting
import "chartkick/chart.js"

// ActionText
require("trix")
require("@rails/actiontext")